import React from "react";
import Navigation from "../components/Navigation";

const NotFound = () => {
  return (
    <div>
      <Navigation />
      <br />
      <br />
      <br />
      <br />
      <br />

      <h1 style={{ textAlign: "center" }}>This page could not be found!</h1>
    </div>
  );
};

export default NotFound;
