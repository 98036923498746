import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "./Logo";
import { menu } from "../Database/dataMenu";
import { useState } from "react";
import { ChoixLangue } from './Context/LangueContext';
import { useContext } from "react";
import { useEffect } from "react";
import SousMenu from "./SousMenu";

import { FaCaretDown, FaRegUser, FaStream } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";
import Mouse from "./Mouse";
import { useSelector } from "react-redux";
import { isEmpty } from "../Utlitaires/isEmpty";

{/*  fixe true si menu nav position: fixe  */ }

const Navigation = ({ fixe }) => {

  const HomeDB = useSelector((state) => state.homeReducer);
  const [DataLANGUE, setDataLANGUE] = useState(menu[0].FR);
  const [Lang, setLang] = useState("EN");

  const { toggleLangue } = useContext(ChoixLangue);
  const { LANGUE } = useContext(ChoixLangue)

  const [sousMenu, setsousMenu] = useState(false)


  const onMouseEnter = () => {
    setsousMenu(true);
  }

  const onMouseLeave = () => {
    setsousMenu(false);

  }

  useEffect(() => {

    if (LANGUE === 0) {
      setDataLANGUE(menu[0].FR);
      setLang("EN");
    } else {
      setDataLANGUE(menu[0].EN);
      setLang("FR");
    }

  }, [LANGUE])

  return (
    <>
      {!isEmpty(HomeDB) && <Mouse fixe={fixe} />}
      <div className="navigation">


        <NavLink exact to="/" >
          <Logo />
        </NavLink>
        <div className="menu">
          <button
            className="titre-avec-sous-menu"
            onMouseEnter={onMouseEnter}
            onClick={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            {DataLANGUE[0].titre}
            <FaCaretDown />
            {sousMenu && <SousMenu />}
          </button>
          <NavLink exact to={DataLANGUE[4].link} className={(nav) => nav.isActive ? "nav-active" : "titre-menu"}>
            {DataLANGUE[4].titre}
          </NavLink>
          <NavLink exact to={DataLANGUE[5].link} className={(nav) => nav.isActive ? "nav-active" : "titre-menu"}>
            {DataLANGUE[5].titre}
          </NavLink>
          <div className="empty-layout" />
          <div className="tools">
            {/* <div className="icone-user">
              <button className="far" title="User"><FaRegUser /></button>
            </div>
            <div className="icone-insta">
              <button className="fas" title="Instagram"><FaStream /></button>
            </div> */}
            <button onClick={toggleLangue} title="Language">{Lang}</button>
          </div>
        </div>

        {/* BURGER */}
        <nav>
          <div className="menu-burger">

            <div className="burger">

              <span ><HiMenu /></span>

              <ul className="sous-menu-burger">
                <NavLink to="/" className={(nav) => nav.isActive ? "nav-active" : "titre-menu"}>
                  {DataLANGUE[7].titre}
                </NavLink>
                <NavLink to={DataLANGUE[4].link} className={(nav) => nav.isActive ? "nav-active" : "titre-menu"}>
                  {DataLANGUE[4].titre}
                </NavLink>
                <NavLink to={DataLANGUE[5].link} className={(nav) => nav.isActive ? "nav-active" : "titre-menu"}>
                  {DataLANGUE[5].titre}
                </NavLink>
                <div
                  className="titre-avec-sous-menu"
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}>
                  {DataLANGUE[0].titre}
                  <FaCaretDown />
                  {sousMenu && <SousMenu />}
                </div>
              </ul>
            </div>
            <div className="tools">
              {/* <div className="icone-user">
                <button className="far"><FaRegUser /></button>
              </div>
              <div className="icone-insta">
                <button className="fas"><FaStream /></button>
              </div> */}
              <button onClick={toggleLangue}>{Lang}</button>
            </div>
          </div>

        </nav>



      </div>
    </>
  );
};

export default Navigation;
