export const ExpProd = [{
    FR: [
        {
            Titre1: "Productions de Films",
            Paragraphe1A: "Notre division de production, réalise films et images toujours plus innovatrices et créatives.",
            Paragraphe1B: "Film publicitaire, contenu de marque, capsule vidéo ou encore image 3D, Bengale surpassera toutes vos attentes.",
            Titre2: "Motion Design & 3D",
            Paragraphe2A: "Pour la communication de votre entreprise rien ne devrait être impossible.",
            Paragraphe2B: "Grâce au studio Bengale, spécialisé en image et vidéo 3D, toutes les idées, même les plus folles sont réalisables.",
            Titre3: "Drone",
            Paragraphe3A: "Nous avons été les pionniers et leaders dans la prise de vues aériennes pour le cinéma et la télévision.",
            Paragraphe3B: "Bengale continue à rester à la pointe dans ce secteur pour nos propres productions.",
            Titre4: "Images",
            Paragraphe4: "Bengale est à l’écoute de vos besoins, prête à vous conseiller et à vous diriger pour développer votre image.",
            Paragraphe5: "Que ce soit pour vos média sociaux, campagnes publicitaires ou du e-commerces, Bengale réalisera pour vous des images de haute qualité et toute la créativité dont vous aurez besoin.",
            EquipeAgence: "STUDIO BENGALE",
            EquipeTitre1: "ÉQUIPE DE PRODUCTION",
            EquipeTitre2: "L'équipe de production travaille chaque jour pour réaliser et créer du contenu à la hauteur de votre entreprise."
        }
    ],
    EN: [

        {
            Titre1: "Film productions",
            Paragraphe1A: "Our production division produces ever more innovative and creative films and images.",
            Paragraphe1B: "Advertising film, brand content, video clip or 3D image, Bengale will exceed all your expectations.",
            Titre2: "Motion Design & 3D",
            Paragraphe2A: "For your company's communication nothing should be impossible.",
            Paragraphe2B: "Thanks to the Bengale studio, specialized in 3D image and video, all ideas, even the craziest ones, are achievable.",
            Titre3: "Drone",
            Paragraphe3A: "We were the pioneers and leaders in aerial photography for film and television.",
            Paragraphe3B: "Bengale continues to remain at the forefront in this sector for our own productions.",
            Titre4: "Images",
            Paragraphe4: "Bengale is attentive to your needs, ready to advise you and guide you to develop your image.",
            Paragraphe5: "Whether for your social media, advertising campaigns or e-commerce, Bengale will produce high quality images and all the creativity you need.",
            EquipeAgence: "BENGALE STUDIO",
            EquipeTitre1: "PRODUCTION TEAM",
            EquipeTitre2: "The production team works every day to produce and create content that is worthy of your company."
        }
    ]
}
];










