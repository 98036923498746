import Photo1 from "../assets/FilmsEtImages/Prod-photo-1.webp"
import Photo2 from "../assets/FilmsEtImages/Prod-photo-2.webp"
import Photo3 from "../assets/FilmsEtImages/Prod-photo-3.webp"
import Photo4 from "../assets/FilmsEtImages/montage.webp"
import Photo5 from "../assets/FilmsEtImages/Prod-photo-5.webp"
import Photo6 from "../assets/FilmsEtImages/Prod-photo-6.webp"


export const CartesProd = [{
    FR: [
        {
            Photo: Photo1,
            Titre: "Producteur",
            SousTitre: "équipe de production",
            Text: "Spécialiste en production de films depuis plus de 20 ans, notre producteur vous garantit le meilleur de son savoir-faire."
        },
        {
            Photo: Photo2,
            Titre: "Réalisateurs",
            SousTitre: "équipe de production",
            Text: "Nos réalisateurs sont toujours à la recherche d’idées nouvelles et originales."
        },
        {
            Photo: Photo3,
            Titre: "Coloriste",
            SousTitre: "post-production",
            Text: "Nos coloristes jouent un rôle aussi important que les autres, ils rendent les images plus belles et éclatantes."
        },
        {
            Photo: Photo4,
            Titre: "Monteurs",
            SousTitre: "post-production",
            Text: "Bout a bout le monteur réalise les films de vos rêves."
        },
        {
            Photo: Photo5,
            Titre: "Compositeurs",
            SousTitre: "post-production",
            Text: "Nos compositeurs créent  la mélodie  parfaite pour transmettre un maximum d’émotion à travers vos films."
        },
        {
            Photo: Photo6,
            Titre: "Illustrateurs",
            SousTitre: "post-production",
            Text: "Nos illustrateurs ne manqueront pas d’imagination, ils sauront vous surprendre lors de leurs réalisation de storyboard et autres croquis."
        }
    ],
    EN: [
        {
            Photo: Photo1,
            Titre: "Producer",
            SousTitre: "production team",
            Text: "Specialized in film production for more than 20 years, our producer guarantees you the best of his know-how."
        },
        {
            Photo: Photo2,
            Titre: "Directors",
            SousTitre: "production team",
            Text: "Our directors are always looking for new and original ideas."
        },
        {
            Photo: Photo3,
            Titre: "Colorist",
            SousTitre: "post-production",
            Text: "Our colorists play an equally important role in making the images more beautiful."
        },
        {
            Photo: Photo4,
            Titre: "Monteurs",
            SousTitre: "post-production",
            Text: "End to end the editor realizes the films of your dreams."
        },
        {
            Photo: Photo5,
            Titre: "Composers",
            SousTitre: "post-production",
            Text: "Our composers create the perfect melody to convey maximum emotion through your films."
        },
        {
            Photo: Photo6,
            Titre: "Illustrators",
            SousTitre: "post-production",
            Text: "Our illustrators will not lack imagination, they will know how to surprise you when they create storyboards and other sketches."
        }

    ]

}];