import React, { useContext, useEffect, useState } from 'react';

import { ChoixLangue } from '../components/Context/LangueContext';
import { CartesMediaSociaux } from "../Database/dataCartesMediaSociaux"


const CartePostes = () => {

    const [DataProd, setDataProd] = useState(CartesMediaSociaux[0].FR);

    const [Lang, setLang] = useState("EN");

    const { LANGUE } = useContext(ChoixLangue)

    useEffect(() => {

        if (LANGUE === 0) {
            setDataProd(CartesMediaSociaux[0].FR);
            setLang("EN");
        } else {
            setDataProd(CartesMediaSociaux[0].EN);
            setLang("FR");
        }

    }, [LANGUE])


    const CreatCarte = (carte, index) => {
        return (
            <div className="Carte-MediaSociaux" key={index}>
                <img className="Photo" src={carte.Photo} alt="Picture" />
                <div className='Text_Carte'>
                    <span className="Titre">{carte.Titre}</span>
                    <span className="SousTitre">{carte.SousTitre}</span>
                    <span className="Text">{carte.Text}</span>
                </div>
            </div>
        );

    };

    return <div className="Cartes">{DataProd.map(CreatCarte)}</div>;
};

export default CartePostes;