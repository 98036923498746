import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ChoixLangue } from '../components/Context/LangueContext';
import { isEmpty } from "../Utlitaires/isEmpty";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin"




gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const DescriptionHome = () => {

    const HomeDB = useSelector((state) => state.homeReducer);
    const { LANGUE } = useContext(ChoixLangue)


    const slideHorizontalIn = (element, direction, delay, duration) => {
        gsap.fromTo(
            element,
            {
                opacity: 0,
                x: direction || -100, // -400 ou 400 for example
            },
            {
                opacity: 1,
                x: 0,
                delay: delay || 0.4,
                duration: duration || 0.6,
                scrollTrigger: {
                    trigger: element,
                    scrub: true,
                    end: "bottom 70%"
                }
            }
        )
    }
    const slideVerticalIn = (element, direction, delay, duration) => {
        gsap.fromTo(
            element,
            {
                opacity: 0,
                y: direction || -100, // -400 ou 400 for example
            },
            {
                opacity: 1,
                y: 0,
                delay: delay || 0.4,
                duration: duration || 0.6,
                scrollTrigger: {
                    trigger: element,
                    scrub: true,
                    start: "top 80%"
                }
            }
        )
    }



    useEffect(() => {

        slideHorizontalIn(".desc-1", -400, 0, 1);
        slideVerticalIn(".desc-2", -200, 0, 0.5);
        slideHorizontalIn(".desc-3", 400, 0, 1);

    }, [HomeDB]);



    return (
        <>
            {!isEmpty(HomeDB) && (
                <div className="descriptionHome">
                    <div className="desc-1">
                        <img className="icone" src="./img/Icone-1.png" alt="Icone" />
                        <h2 className="titre">{HomeDB[LANGUE].TitreExpert1}</h2>
                        <p className="detail" >{HomeDB[LANGUE].DescrExpert1}</p>
                    </div>
                    <div className="desc-2">
                        <img className="icone" src="./img/Icone-2.png" alt="Icone" />
                        <h2 className="titre">{HomeDB[LANGUE].TitreExpert2}</h2>
                        <p className="detail" >{HomeDB[LANGUE].DescrExpert2}</p>
                    </div>
                    <div className="desc-3">
                        <img className="icone" src="./img/Icone-3.png" alt="Icone" />
                        <h2 className="titre">{HomeDB[LANGUE].TitreExpert3}</h2>
                        <p className="detail" >{HomeDB[LANGUE].DescrExpert3}</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default DescriptionHome;