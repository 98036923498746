import Photo1 from "../assets/productions/Films/codiplas.webp"
import Photo2 from "../assets/productions/Films/next motion.webp"
import Photo3 from "../assets/productions/Films/indeed.webp"
import Photo4 from "../assets/productions/Films/4x4.webp"
import Photo5 from "../assets/productions/Films/5vie.webp"
import Photo6 from "../assets/productions/Films/provence huile.webp"
import Photo7 from "../assets/productions/Films/ky.webp"
import Photo8 from "../assets/productions/Films/lutier.webp"
import Photo9 from "../assets/productions/Films/saussison.webp"
import Photo10 from "../assets/productions/Films/Hyatt.webp"
import Photo11 from "../assets/productions/Films/Teoxane.webp"
import Photo12 from "../assets/productions/Films/AssassinsCreed.webp"
import Photo13 from "../assets/productions/Films/nitro.webp"
import Photo14 from "../assets/productions/Films/jotul.webp"
import Photo15 from "../assets/productions/Films/hochelaga.webp"
import Photo16 from "../assets/productions/Films/subaru.webp"
import Photo17 from "../assets/productions/Films/fenetre.webp"
import Photo18 from "../assets/productions/Films/votez bongons.webp"
import Photo19 from "../assets/productions/Films/3 petits cochons.webp"
import Photo20 from "../assets/productions/Films/waza.webp"
import Photo21 from "../assets/productions/Films/Drone.webp"
import Photo22 from "../assets/productions/Films/4Saisons.webp"
import Photo23 from "../assets/productions/Films/UPU.webp"
import Photo24 from "../assets/productions/Films/Omnibook.webp"
import Photo25 from "../assets/productions/Films/Fredange.webp"
import Photo26 from "../assets/productions/Films/DroneBA.webp"
import Photo27 from "../assets/productions/Films/RemyMartin.webp"
import Photo28 from "../assets/productions/Films/la petite justine.webp"
import Photo29 from "../assets/productions/Films/sicilia.webp"
import Photo30 from "../assets/productions/Films/OHCHR.webp"
import Photo31 from "../assets/productions/Films/VTT.webp"
import Photo32 from "../assets/productions/Films/PIPA.webp"
import Photo33 from "../assets/productions/Films/IUCN.webp"

import Image1 from "../assets/productions/Images/RemyMartin.webp"
import Image2 from "../assets/productions/Images/Maeva.webp"
import Image3 from "../assets/productions/Images/ProvenceHuiles.webp"
import Image4 from "../assets/productions/Images/Prada.webp"
import Image5 from "../assets/productions/Images/V-Kosmetik-1.webp"
import Image6 from "../assets/productions/Images/Codiplas.webp"
import Image7 from "../assets/productions/Images/Codiplas2.webp"
import Image8 from "../assets/productions/Images/Dior.webp"
import Image9 from "../assets/productions/Images/BA4x4.webp"
import Image10 from "../assets/productions/Images/Banana-republique.webp"
import Image11 from "../assets/productions/Images/BienManger.webp"
import Image12 from "../assets/productions/Images/Marc.webp"
import Image13 from "../assets/productions/Images/Mila.webp"
import Image14 from "../assets/productions/Images/Mercedes.webp"
import Image15 from "../assets/productions/Images/pompeCodiplas.webp"
import Image16 from "../assets/productions/Images/produit-v-k.webp"
import Image17 from "../assets/productions/Images/Veronique.webp"
import Image18 from "../assets/productions/Images/RobertLegare-1.webp"
import Image19 from "../assets/productions/Images/RobertLegare-A1.webp"
import Image20 from "../assets/productions/Images/RobertLegare-B1.webp"

export const production = [{
    FR: [
        {
            titre1: "Plus De 300 Films",
            titre2: "Réalisés Par Notre Division Productions",
            titre3: "FILMS",
            filtre1: ["Tout", "Corporatif", "3D", "Pub", "Jeux / Cinéma / TV", "Documentaire", "Web"],
            titre4: "IMAGES",
            filtre2: ["Tout", "Mode", "Produit", "Web", "3D"]
        }
    ],
    EN: [
        {
            titre1: "More Than 300 Films",
            titre2: "Carried Out By Our Production Division",
            titre3: "FILMS",
            filtre1: ["All", "Corporat", "3D", "Advertising", "Games / Movies / TV", "Documentary", "Web"],
            titre4: "PICTURES",
            filtre2: ["All", "Fashion", "Product", "Web", "3D"]
        }
    ]
}
];


export const films = [
    {
        id: "1",
        Categorie: "1",
        Photo: Photo1,
        Titre: "CODIPLAS",
        Lien: "https://player.vimeo.com/video/346457302"
    },
    {
        id: "2",
        Categorie: "3",
        Photo: Photo2,
        Titre: "NEXT MOTION",
        Lien: "https://player.vimeo.com/video/553389818"
    },
    {
        id: "3",
        Categorie: "3",
        Photo: Photo24,
        Titre: "OMNIBOOK",
        Lien: "https://player.vimeo.com/video/723343815"
    },
    {
        id: "4",
        Categorie: "all",
        Photo: Photo21,
        Titre: "DEMO DRONE",
        Lien: "https://player.vimeo.com/video/251384998"
    },
    {
        id: "5",
        Categorie: "3",
        Photo: Photo3,
        Titre: "INDEED",
        Lien: "https://player.vimeo.com/video/242094694"
    },
    {
        id: "6",
        Categorie: "3",
        Photo: Photo4,
        Titre: "BODY ARMOR 4X4 USA",
        Lien: "https://player.vimeo.com/video/551691749"
    },
    {
        id: "7",
        Categorie: "1",
        Photo: Photo6,
        Titre: "PROVENCE HUILES",
        Lien: "https://player.vimeo.com/video/346460527"
    },
    {
        id: "8",
        Categorie: "1",
        Photo: Photo7,
        Titre: "K&Y DIAMOND",
        Lien: "https://player.vimeo.com/video/97274618"
    },
    {
        id: "9",
        Categorie: "6",
        Photo: Photo9,
        Titre: "SALAISON DU MONT CHARVIN",
        Lien: "https://player.vimeo.com/video/346459747"
    },
    {
        id: "10",
        Categorie: "6",
        Photo: Photo8,
        Titre: "YVES GHIROTTO - LUTHIER",
        Lien: "https://player.vimeo.com/video/346454749"
    },
    {
        id: "11",
        Categorie: "4",
        Photo: Photo15,
        Titre: "HOCHELAGA (DRONE)",
        Lien: "https://player.vimeo.com/video/242098437"
    },
    {
        id: "12",
        Categorie: "4",
        Photo: Photo5,
        Titre: "NIVE LIVES (DRONE)",
        Lien: "https://player.vimeo.com/video/160282991"
    },
    {
        id: "13",
        Categorie: "6",
        Photo: Photo25,
        Titre: "FREDANGE - SCULPTEUR",
        Lien: "https://player.vimeo.com/video/723380925"
    },
    {
        id: "14",
        Categorie: "3",
        Photo: Photo10,
        Titre: "HYATT",
        Lien: "https://player.vimeo.com/video/184576354"
    },
    {
        id: "15",
        Categorie: "2",
        Photo: Photo11,
        Titre: "TEOXANE",
        Lien: "https://player.vimeo.com/video/553383308"
    },
    {
        id: "16",
        Categorie: "4",
        Photo: Photo12,
        Titre: "ASSASSIN'S CREED IV",
        Lien: "https://player.vimeo.com/video/108736540"
    },
    {
        id: "17",
        Categorie: "4",
        Photo: Photo13,
        Titre: "NITRO RUSH (DRONE)",
        Lien: "https://player.vimeo.com/video/169868918"
    },
    {
        id: "18",
        Categorie: "3",
        Photo: Photo14,
        Titre: "JOTUL",
        Lien: "https://player.vimeo.com/video/97258621"
    },
    {
        id: "19",
        Categorie: "3",
        Photo: Photo26,
        Titre: "DRONE BLACK ANGELS",
        Lien: "https://player.vimeo.com/video/723381166"
    },
    {
        id: "20",
        Categorie: "5",
        Photo: Photo28,
        Titre: "LE COEUR DE JUSTINE",
        Lien: "https://player.vimeo.com/video/97541605"
    },
    {
        id: "21",
        Categorie: "4",
        Photo: Photo18,
        Titre: "VOTEZ BOUGON (DRONE)",
        Lien: "https://player.vimeo.com/video/228123911"
    },
    {
        id: "22",
        Categorie: "4",
        Photo: Photo19,
        Titre: "LES 3 PETITS COCHONS 2 (DRONE)",
        Lien: "https://player.vimeo.com/video/228124595"
    },
    {
        id: "23",
        Categorie: "4",
        Photo: Photo16,
        Titre: "SUBARU FORESTER (DRONE)",
        Lien: "https://player.vimeo.com/video/108735024"
    },
    {
        id: "24",
        Categorie: "5",
        Photo: Photo20,
        Titre: "WAZA - NATIONS UNIES",
        Lien: "https://player.vimeo.com/video/104445021"
    },
    {
        id: "25",
        Categorie: "2",
        Photo: Photo23,
        Titre: "UPU -UNIVERSAL POSTAL UNION",
        Lien: "https://player.vimeo.com/video/116991608"
    },
    {
        id: "26",
        Categorie: "1",
        Photo: Photo32,
        Titre: "PIPA",
        Lien: "https://player.vimeo.com/video/724446619"
    },
    {
        id: "27",
        Categorie: "3",
        Photo: Photo33,
        Titre: "IUCN",
        Lien: "https://player.vimeo.com/video/723511932"
    },
    {
        id: "28",
        Categorie: "6",
        Photo: Photo22,
        Titre: "FOUR SEASONS",
        Lien: "https://player.vimeo.com/video/286077207"
    },
    {
        id: "29",
        Categorie: "3",
        Photo: Photo29,
        Titre: "SICILIA",
        Lien: "https://player.vimeo.com/video/227185456"
    },
    {
        id: "30",
        Categorie: "3",
        Photo: Photo30,
        Titre: "OHCHR",
        Lien: "https://player.vimeo.com/video/97363881"
    },
    {
        id: "31",
        Categorie: "2",
        Photo: Photo27,
        Titre: "REMY MARTIN",
        Lien: "https://player.vimeo.com/video/723342890"
    },
    {
        id: "32",
        Categorie: "6",
        Photo: Photo17,
        Titre: "FENETRES NF",
        Lien: "https://player.vimeo.com/video/227186208"
    },
];

export const images = [
    {
        id: "1",
        Categorie: "1",
        Photo: Image10,
        Titre: "BANANA REPUBLIQUE",
    },
    {
        id: "2",
        Categorie: "2",
        Photo: Image4,
        Titre: "PRADA",
    },
    {
        id: "3",
        Categorie: "2",
        Photo: Image1,
        Titre: "REMY MARTIN",
    },
    {
        id: "4",
        Categorie: "2",
        Photo: Image2,
        Titre: "DOMYOS - DECATLON",
    },
    {
        id: "5",
        Categorie: "3",
        Photo: Image3,
        Titre: "PROVENCE HUILES",
    },
    {
        id: "6",
        Categorie: "2",
        Photo: Image6,
        Titre: "CODIPLAS",
    },
    {
        id: "7",
        Categorie: "2",
        Photo: Image7,
        Titre: "Le Couvent Des Minimes",
    },
    {
        id: "8",
        Categorie: "2",
        Photo: Image8,
        Titre: "DIOR",
    },
    {
        id: "9",
        Categorie: "3",
        Photo: Image9,
        Titre: "BA 4X4 USA",
    },
    {
        id: "10",
        Categorie: "3",
        Photo: Image11,
        Titre: "PROVENCE HUILES",
    },
    {
        id: "11",
        Categorie: "1",
        Photo: Image12,
        Titre: "MARC",
    },
    {
        id: "12",
        Categorie: "3",
        Photo: Image13,
        Titre: "MILA",
    },
    {
        id: "13",
        Categorie: "2",
        Photo: Image14,
        Titre: "MERCEDES-BENZ",
    },
    {
        id: "14",
        Categorie: "1",
        Photo: Image17,
        Titre: "VERONIQUE",
    },
    {
        id: "15",
        Categorie: "1",
        Photo: Image5,
        Titre: "V-KOSMETIK",
    },
    {
        id: "16",
        Categorie: "2",
        Photo: Image16,
        Titre: "V-KOSMETIK",
    },
    {
        id: "17",
        Categorie: "2",
        Photo: Image15,
        Titre: "CODIPLAS",
    },
    {
        id: "18",
        Categorie: "4",
        Photo: Image18,
        Titre: "ROBERT LÉGARÉ",
    },
    {
        id: "19",
        Categorie: "4",
        Photo: Image19,
        Titre: "ROBERT LÉGARÉ",
    },
    {
        id: "20",
        Categorie: "4",
        Photo: Image20,
        Titre: "ROBERT LÉGARÉ",
    },

]