export const ExpWeb = [{
    FR: [
        {
            Titre1: "Application WEB & Mobile",
            Paragraphe1: "Notre équipe réalisera toute la conception de votre site internet pour qu’il soit fluide et en harmonie. Un gros avantage de nous faire confiance et de garantir une qualité globale pour toute la communication de votre entreprise de la conception au contenu.",
            Titre2: "E-Commerce",
            Paragraphe2: "Notre équipe travaille toujours avec la dernière technologie pour garantir la meilleure fiabilité et faciliter pour une plateforme des plus agréable pour vos clients.",
            EquipeAgence: "STUDIO BENGALE",
            EquipeTitre1: "ÉQUIPE WEB",
            EquipeTitre2: "besoin d'une application web ou mobile, notre équipe est la !"
        }
    ],
    EN: [
        {
            Titre1: "WEB Application & Mobile",
            Paragraphe1: "Our team will carry out all the design of your website so that it is fluid and in harmony. A big advantage of trusting us and guaranteeing a global quality for all the communication of your company from design to content.",
            Titre2: "E-Commerce",
            Paragraphe2: "Our team always works with the latest technology to guarantee the best reliability and facilitate for a most pleasant platform for your customers.",
            EquipeAgence: "BENGALE STUDIO",
            EquipeTitre1: "WEB TEAM",
            EquipeTitre2: "need a web application & mobile, our team is here !"
        }

    ]
}
];










