import React from 'react';
import Navigation from '../components/Navigation';

const Expertises = () => {
  return (
    <div className="expertises">
      <Navigation />
      <h1>EXPERTISES</h1>
    </div>
  );
};

export default Expertises;