import React from 'react';
import Logo from './Logo';

const Copyright = () => {

    var date = new Date();
    var year = date.getFullYear();



    return (
        <div className="copyright">
            <h4 className="text">Copyright © {year} All rights reserved to Bengale</h4>
            <Logo className="logo" />
        </div>
    );
};

export default Copyright;