import React, { useContext, useEffect, useState } from "react";
import Copyright from "../../components/Copyright";
import Footer from "../../components/Footer";
import Navigation from "../../components/Navigation";
import Image1 from "../../assets/mediaSociaux/MediaSociaux-Photo-1.webp"
import Image2 from "../../assets/mediaSociaux/MediaSociaux-Photo-2.webp"
import Image3 from "../../assets/mediaSociaux/MediaSociaux-Photo-3.webp"
// import Image3 from "../../assets/FilmsEtImages/Image-1.webp"
import ImageFond from "../../assets/mediaSociaux/Image-fond.webp"
import CartePostesMedisSociaux from "../../components/CartePostesMediaSociaux";
import { ChoixLangue } from '../../components/Context/LangueContext';
import { ExpMediaSociaux } from "../../Database/dataExpMediaSociaux";

const MediaSociaux = () => {

  const [DataMediaSociaux, setDataMediaSociaux] = useState(ExpMediaSociaux[0].FR);
  const [Lang, setLang] = useState("EN");

  const { LANGUE } = useContext(ChoixLangue)

  useEffect(() => {

    if (LANGUE === 0) {
      setDataMediaSociaux(ExpMediaSociaux[0].FR);
      setLang("EN");
    } else {
      setDataMediaSociaux(ExpMediaSociaux[0].EN);
      setLang("FR");
    }

  }, [LANGUE])

  return (
    <div className="MediaSociaux">
      <img className="Photo-fond" src={ImageFond} alt="Picture" />
      <Navigation />
      <div className="Section-1">
        <div className="left">
          <h1>{DataMediaSociaux[0].Titre1}</h1>
          <p>{DataMediaSociaux[0].Paragraphe1}</p>
        </div>
        <div className="right">
          <img className="Photo-media-sociaux" src={Image1} alt="Picture" />
        </div>
      </div>
      <div className="Section-2">
        <div className="left">
          <h1>{DataMediaSociaux[0].Titre2}</h1>
          <p>{DataMediaSociaux[0].Paragraphe2}</p>
        </div>
        <div className="right">
          <img className="Photo-media-sociaux" src={Image2} alt="Picture" />
        </div>
      </div>
      <div className="Section-3">
        <div className="left">
          <img className="Photo-media-sociaux" src={Image3} alt="Picture" />
          {/* <img className="Photo-media-sociaux" src={Image3} alt="Picture" /> */}
        </div>
        <div className="right">
          <h1>{DataMediaSociaux[0].Titre3}</h1>
          <p>{DataMediaSociaux[0].Paragraphe3}</p>
        </div>
      </div>
      <div className="Section-4">

        <h3>{DataMediaSociaux[0].EquipeAgence}</h3>
        <h2 >{DataMediaSociaux[0].EquipeTitre1}</h2>
        <h2 className="sous-titre">{DataMediaSociaux[0].EquipeTitre2}</h2>

      </div>

      <CartePostesMedisSociaux />
      <Footer />
      <Copyright />


    </div>
  );
};

export default MediaSociaux;
