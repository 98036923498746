import { GET_HOME } from "../Actions/home.action";

const initialState = {};

export default function homeReducer(state = initialState, action) {
    switch (action.type) {
        case GET_HOME:
            return action.payload;

        default:
            return state;

    }
}

