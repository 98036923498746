import Photo1 from "../assets/mediaSociaux/MediaSociaux-Photo-4.webp"
import Photo2 from "../assets/mediaSociaux/MediaSociaux-Photo-5.webp"


export const CartesMediaSociaux = [{
    FR: [
        {
            Photo: Photo1,
            Titre: "Gestionnaires des réseaux sociaux",
            SousTitre: "spécialiste en communication",
            Text: "Notre équipe de gestionnaire des réseaux sociaux analyseront votre audience et partagent de façon stratégique votre contenu pour une communication maximisée."
        },
        {
            Photo: Photo2,
            Titre: "Spécialistes en stratégie",
            SousTitre: "stratégie marketing",
            Text: "Nos spécialistes pensent et développent les meilleures stratégies  marketing pour votre entreprise."
        }

    ],
    EN: [
        {
            Photo: Photo1,
            Titre: "Social network managers",
            SousTitre: "communication specialist",
            Text: "Our team of social network managers will analyze your audience and strategically share your content for maximum communication."
        },
        {
            Photo: Photo2,
            Titre: "Strategy specialists",
            SousTitre: "marketing strategy",
            Text: "Our specialists think and develop the best marketing strategies for your business."
        }


    ]

}];