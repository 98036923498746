import { createContext, useState } from "react";

export const ChoixLangue = createContext();

const ChoixLangueProvider = props => {

    const [LANGUE, setLANGUE] = useState(0)

    const toggleLangue = () => {
        if (LANGUE === 0) {
            setLANGUE(1);
        } else { setLANGUE(0); }
    }


    return (
        <ChoixLangue.Provider value={{ LANGUE, toggleLangue }}>
            {props.children}
        </ChoixLangue.Provider>
    )
}

export default ChoixLangueProvider;
