import React from "react";
import { useEffect } from "react";
import { useSelector } from 'react-redux';
import Navigation from "../components/Navigation";
import { isEmpty } from "../Utlitaires/isEmpty";
import VideoFond from "../assets/home/VideoFond.webm";
import PosterFond from "../assets/home/PosterFond.webp"
import { useContext } from "react";
import { ChoixLangue } from '../components/Context/LangueContext';
import LogosRef from "../components/LogosRef";
import PhotosVideosHome from "../components/PhotosVideosHome";
import DescriptionHome from "../components/DescriptionHome";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Mouse from "../components/Mouse";


const Home = () => {

  // const dispatch = useDispatch();

  const HomeDB = useSelector((state) => state.homeReducer);

  const { LANGUE } = useContext(ChoixLangue)

  useEffect(() => {


  }, [LANGUE])



  return (
    <div className="home">
      <div className="big-screen">
        <Navigation />


        {!isEmpty(HomeDB) && (
          <div className="vimeo-wrapper">
            {/* <img src={PosterFond} /> */}
            {/* <iframe src={"https://player.vimeo.com/video/" + "551700019" + "?background=1&autoplay=1&loop=1&byline=0&title=0"}> </iframe> */}
            <video autoPlay loop muted src={VideoFond} type="video/webm"> </video>
          </div>)
        }
        {
          !isEmpty(HomeDB) && (
            <div className="GrosTitre" >
              <h1 className="GrosTitre1">{HomeDB[LANGUE].GrosTitre1}</h1>
              <h1 className="GrosTitre2">{HomeDB[LANGUE].GrosTitre2}</h1>
            </div>)
        }
        {
          !isEmpty(HomeDB) && (
            <div className="PetitTitre">
              <h2 className="PetitTitre1">{HomeDB[LANGUE].PetitTitre1}</h2>
              <h2 className="PetitTitre2">{HomeDB[LANGUE].PetitTitre2}</h2>
              <h2 className="PetitTitre3">{HomeDB[LANGUE].PetitTitre3}</h2>
            </div>
          )
        }
      </div >

      {/* PETIT ECRAN */}
      <div className="small-screen">

        <Navigation />
        <section className="section-1">

          <img className="logo-burger" src="./img/BENGALE-VERTICAL.png" alt="logo" exact to="/" />

        </section>
        <section className="section-2">
          <div className="background-video">
            {/* <img src={PosterFond} /> */}
            {/* <iframe src={"https://player.vimeo.com/video/" + "551700019" + "?background=1&autoplay=1&loop=1&byline=0&title=0"}> </iframe> */}
            <video id="background-video" autoPlay loop muted src={VideoFond} type="video/webm" > </video>
          </div>
          {
            !isEmpty(HomeDB) && (
              <div className="GrosTitre" >
                <h1 className="GrosTitre1">{HomeDB[LANGUE].GrosTitre1}</h1>
                <h1 className="GrosTitre2">{HomeDB[LANGUE].GrosTitre2}</h1>
              </div>)
          }
          {
            !isEmpty(HomeDB) && (
              <div className="PetitTitre">
                <h2 className="PetitTitre1">{HomeDB[LANGUE].PetitTitre1}</h2>
                <h2 className="PetitTitre2">{HomeDB[LANGUE].PetitTitre2}</h2>
                <h2 className="PetitTitre3">{HomeDB[LANGUE].PetitTitre3}</h2>
              </div>
            )
          }
        </section>
      </div >

      <LogosRef />
      <PhotosVideosHome />
      <DescriptionHome />

      <Footer />
      <Copyright />


    </div>


  );
};
export default Home;