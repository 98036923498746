import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { isEmpty } from "../Utlitaires/isEmpty";
import { useSelector } from 'react-redux';
import { ChoixLangue } from './Context/LangueContext';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const PhotosVideosHome = () => {

    const HomeDB = useSelector((state) => state.homeReducer);
    const { LANGUE } = useContext(ChoixLangue)


    //ANIMATION LATTERALE SUR LE SCROLL
    const slideHorizontalIn = (element, direction, delay, duration) => {
        gsap.fromTo(
            element,
            {
                opacity: 0,
                x: direction || -100, // -400 ou 400 for example
            },
            {
                opacity: 1,
                x: 0,
                delay: delay || 0.4,
                duration: duration || 0.6,
                scrollTrigger: {
                    trigger: element,
                    scrub: true,
                    end: "bottom 70%"
                }
            }
        )
    }


    useEffect(() => {

        slideHorizontalIn(".Petite-Photo-1", -400, 0.5, 2);
        slideHorizontalIn(".petit-film-1", 400, 0.5, 2);
        slideHorizontalIn(".petit-film-2", -400, 0.5, 2);
        slideHorizontalIn(".Petite-Photo-3", 400, 0.5, 2);
        slideHorizontalIn(".Petite-Photo-4", -400, 0.5, 2);
        slideHorizontalIn(".Petite-Photo-5", 400, 0.5, 2);
        slideHorizontalIn(".petit-film-3", 400, 0.5, 2);

    }, [HomeDB]);



    return (
        <div className="PhotosVideosHome">
            {
                !isEmpty(HomeDB) && (
                    <iframe className="grand-film" src={"https://player.vimeo.com/video/" + HomeDB[0].GrandFilm + "?background=1&autoplay=1&loop=1&byline=0&title=0"} > </iframe>
                )}



            {!isEmpty(HomeDB) && (
                <div className="Petites-Photo-Film">
                    <img className="Petite-Photo-1" src={HomeDB[LANGUE].PetiteImage1} alt="Picture" />
                    < img className="Petite-Photo-1" src={HomeDB[LANGUE].PetiteImage2} alt="Picture" />
                    <iframe className="petit-film-1" src={"https://player.vimeo.com/video/" + HomeDB[0].PetitFilm1 + "?background=1&autoplay=1&loop=1&byline=0&title=0"} width="650" height="275" > </iframe>
                </div>

            )}


            {
                !isEmpty(HomeDB) && (
                    <div className="Petit-Film-photo">
                        <iframe className="petit-film-2" src={"https://player.vimeo.com/video/" + HomeDB[0].PetitFilm2 + "?background=1&autoplay=1&loop=1&byline=0&title=0"} > </iframe>
                        <img className="Petite-Photo-3" src={HomeDB[LANGUE].PetiteImage3} alt="Picture" />
                    </div>
                )}

            {
                !isEmpty(HomeDB) && (
                    <div className="Grande-Photo-photo-film">
                        <img className="Petite-Photo-4" src={HomeDB[LANGUE].GrandeImage1} alt="Picture" />
                        <img className="Petite-Photo-5" src={HomeDB[LANGUE].PetiteImage4} alt="Picture" />
                        <iframe className="petit-film-3" src={"https://player.vimeo.com/video/" + HomeDB[0].PetitFilm3 + "?background=1&autoplay=1&loop=1&byline=0&title=0"} > </iframe>

                    </div>

                )}
        </div>
    );
};

export default PhotosVideosHome;