import React, { useContext, useEffect, useState } from "react";
import Copyright from "../components/Copyright";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import { ChoixLangue } from '../components/Context/LangueContext';
import { production, films, images } from "../Database/dataProduction";
import { MdOutlinePlayCircle } from "react-icons/md"
import EcranFilms from "../components/EcranFilms";
import { NavLink } from "react-router-dom";

const Productions = () => {

  const [DataProd, setDataProd] = useState(production[0].FR);
  const [Lang, setLang] = useState("EN");
  const [HoverOn, setHoverOn] = useState(false);
  const [ToggleTabsFilms, setToggleTabsFilms] = useState("all");
  const [ToggleTabsImages, setToggleTabsImages] = useState("all");

  const { LANGUE } = useContext(ChoixLangue)


  useEffect(() => {

    if (LANGUE === 0) {
      setDataProd(production[0].FR);
      setLang("EN");
    } else {
      setDataProd(production[0].EN);
      setLang("FR");
    }

  }, [LANGUE])



  return (
    <div className="Production">
      <div className="Entete">
        <Navigation />
        <div className="videofond">
          <iframe className="Film-Fond" src={"https://player.vimeo.com/video/" + "552445638" + "?background=1&autoplay=1&loop=1&byline=0&title=0"} width="650" height="275" > </iframe>
        </div>

        <div className="GrosTitre-Production" >
          <h1 className="GrosTitre1">{DataProd[0].titre1}</h1>
          <h1 className="GrosTitre1">{DataProd[0].titre2}</h1>
          <div className="SousTitre">
            {/* <h1 className="GrosTitre2">Black Angels</h1>
            <h1 className="OptionCouleur">.</h1> */}
          </div>
        </div>
      </div>

      <div className="films">
        <h1>{DataProd[0].titre3}</h1>
        <div className="container-onglets">
          <div className="bloc-onglets">
            <div id="onglet" className={ToggleTabsFilms === "all" ? "tabs-active" : "tabs"} onClick={() => setToggleTabsFilms("all")}>{DataProd[0].filtre1[0]}</div>
            <div id="onglet" className={ToggleTabsFilms === "1" ? "tabs-active" : "tabs"} onClick={() => setToggleTabsFilms("1")}>{DataProd[0].filtre1[1]}</div>
            <div id="onglet" className={ToggleTabsFilms === "2" ? "tabs-active" : "tabs"} onClick={() => setToggleTabsFilms("2")}>{DataProd[0].filtre1[2]}</div>
            <div id="onglet" className={ToggleTabsFilms === "3" ? "tabs-active" : "tabs"} onClick={() => setToggleTabsFilms("3")}>{DataProd[0].filtre1[3]}</div>
            <div id="onglet" className={ToggleTabsFilms === "4" ? "tabs-active" : "tabs"} onClick={() => setToggleTabsFilms("4")}>{DataProd[0].filtre1[4]}</div>
            <div id="onglet" className={ToggleTabsFilms === "5" ? "tabs-active" : "tabs"} onClick={() => setToggleTabsFilms("5")}>{DataProd[0].filtre1[5]}</div>
            <div id="onglet" className={ToggleTabsFilms === "6" ? "tabs-active" : "tabs"} onClick={() => setToggleTabsFilms("6")}>{DataProd[0].filtre1[6]}</div>
          </div>
        </div>
        <div className="content-films">
          {films
            .filter((e) => e.Categorie === ToggleTabsFilms || ToggleTabsFilms === "all")
            .map((item, index) => (
              <div onMouseEnter={() => setHoverOn(true)} onMouseLeave={() => setHoverOn(false)} key={index} className="card">
                <NavLink exact to={"/ecran-film/" + item.id}>
                  {/* <a href={item.Lien + "?background=0&autoplay=1&loop=1&byline=0&title=0&transparent=0"} poster={item.Photo}> */}
                  <img src={item.Photo} alt={item.Titre} />
                  <div className="NomFilm">
                    <p >{item.Titre}</p>
                    <span className="Play"><MdOutlinePlayCircle /></span>
                  </div>
                  {/* </a> */}
                </NavLink>
              </div>
            ))}
        </div>
      </div>
      <div className="images">
        <h1>{DataProd[0].titre4}</h1>
        <div className="container-onglets">
          <div className="bloc-onglets">
            <div id="onglet" className={ToggleTabsImages === "all" ? "tabs-active" : "tabs"} onClick={() => setToggleTabsImages("all")}>{DataProd[0].filtre2[0]}</div>
            <div id="onglet" className={ToggleTabsImages === "1" ? "tabs-active" : "tabs"} onClick={() => setToggleTabsImages("1")}>{DataProd[0].filtre2[1]}</div>
            <div id="onglet" className={ToggleTabsImages === "2" ? "tabs-active" : "tabs"} onClick={() => setToggleTabsImages("2")}>{DataProd[0].filtre2[2]}</div>
            <div id="onglet" className={ToggleTabsImages === "3" ? "tabs-active" : "tabs"} onClick={() => setToggleTabsImages("3")}>{DataProd[0].filtre2[3]}</div>
            <div id="onglet" className={ToggleTabsImages === "4" ? "tabs-active" : "tabs"} onClick={() => setToggleTabsImages("4")}>{DataProd[0].filtre2[4]}</div>
          </div>
        </div>
        <div className="content-images">
          <div className="Gallery" >
            {images
              .filter((e) => e.Categorie === ToggleTabsImages || ToggleTabsImages === "all")
              .map((item, index) => (
                <div onMouseEnter={() => setHoverOn(true)} onMouseLeave={() => setHoverOn(false)} key={index} className="card">
                  <NavLink exact to={"/ecran-image/" + item.id}>
                    <img src={item.Photo} alt={item.Titre} />
                    <div className="NomImage">
                      <p >{item.Titre}</p>
                    </div>
                  </NavLink>
                </div>
              ))}
          </div>

        </div>
      </div>


      <Footer />
      <Copyright />

    </div>
  );
};

export default Productions;
