import React from 'react';
import useFirestore from '../Utlitaires/useFirestore';


const LogosRef = () => {
  const { docs } = useFirestore("RefClts");

  return (
    <div className="img-ref">

      {docs && docs.map(doc => (
        <div className="img-wrap" key={doc.id}>
          <img src={doc.url} alt="uploaded pic" />
        </div>
      ))}
      {docs && docs.map(doc => (
        <div className="img-wrap" key={doc.id}>
          <img src={doc.url} alt="uploaded pic" />
        </div>
      ))}
    </div>

  )
}

export default LogosRef;