import React from 'react';
import { useParams } from 'react-router-dom';
import Logo from './Logo';
import Navigation from './Navigation';
import { production, films, images } from "../Database/dataProduction";

const EcranFilms = () => {


    const { id } = useParams();

    var lien = films.filter((e) => e.id === id)


    return (
        <>
            <Navigation />

            <iframe className="Film" src={lien[0].Lien + "?&muted=0&autoplay=1&loop=1&byline=1"} allow="autoplay; fullscreen" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </>
    );
};

export default EcranFilms;