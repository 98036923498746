import React from "react";

import logo from "../assets/logo.png"

const Logo = () => {
  return (
    <div className="logo">
      <img className="imglogo" src={logo} alt="logo" />
    </div>
  );
};

export default Logo;
