export const menu = [{
    FR: [
        {
            titre: "Expertises",
            link: "/expertises",
            cName: "menu"
        },
        {
            titre: "Films et Images ",
            link: "/films-image",
            cName: "sousmenu"
        },
        {
            titre: "Site Internet & Applications",
            link: "/site-web",
            cName: "sousmenu"
        },
        {
            titre: "Média Sociaux",
            link: "/social-media",
            cName: "sousmenu"
        },

        {
            titre: "Productions",
            link: "/productions",
            cName: "menu"
        },
        {
            titre: "Contact",
            link: "/contact",
            cName: "menu"
        },
        {
            titre: "Liens Rapide"
        },
        {
            titre: "Accueil",
        },
        {
            titre: "Votre Nom",
        },
        {
            titre: "Inscription",
        }

    ],

    EN: [

        {
            titre: "Expertise",
            link: "/expertises",
            cName: "menu"
        },
        {
            titre: "Films & Pictures ",
            link: "/films-image",
            cName: "sousmenu"
        },
        {
            titre: "WebSite & Applications",
            link: "/site-web",
            cName: "sousmenu"
        },
        {
            titre: "Social Media",
            link: "/social-media",
            cName: "sousmenu"
        },
        {
            titre: "Productions",
            link: "/productions",
            cName: "menu"
        },
        {
            titre: "Contact us",
            link: "/contact",
            cName: "menu"
        },
        {
            titre: "Quick Links"
        },
        {
            titre: "Home",
        },
        {
            titre: "Your Name",
        },
        {
            titre: "Subscribe",
        }

    ]
}
];










