import React, { useEffect } from 'react';
import { useState } from 'react';

{/*  fixe true si menu nav position: fixe  */ }

const Mouse = ({ fixe }) => {

    console.log("MENU : ", fixe);

    useEffect(() => {
        const curseur = document.querySelector(".curseur");
        let Scroll = 0;

        window.addEventListener("scroll", () => {
            if (fixe !== true) Scroll = window.pageYOffset; {/*  fixe true laisse le scroll à 0 */ }
        });

        window.addEventListener("mousemove", (e) => {
            curseur.style.top = e.y + Scroll - 15 + "px";
            curseur.style.left = e.x - 15 + "px";
        });

        document.querySelectorAll("a, button, #onglet").forEach((link) => {

            link.addEventListener("mouseover", () => {
                curseur.classList.add("hover");
            });

            link.addEventListener("mouseleave", () => {
                curseur.classList.remove("hover");
            });
        });

    }, [])



    return (
        <span className="curseur"></span>
    );
};

export default Mouse;