export const contact = [{
    FR: [
        {
            titre1: "LES ETABLISSEMENTS BENGALE",
            titre2: "CONTACTEZ NOUS",
            formTitreLeft: "MESSAGE",
            formNom: "NOM",
            formEmail: "EMAIL",
            formMessage: "MESSAGE",
            formBouton: "ENVOYER",
            AdressePays1: "CANADA",
            AdresseEmail1: "info@bengale.ca",
            TitrePays1: "CANADA",
            TextRight: "Un membre de notre équipe vous répondra le plus tôt possible."
        }
    ],

    EN: [

        {
            titre1: "THE BENGALE ESTABLISHMENTS",
            titre2: "CONTACT US",
            formTitreLeft: "MESSAGE",
            formNom: "NAME",
            formEmail: "EMAIL",
            formMessage: "MESSAGE",
            formBouton: "SEND",
            AdressePays1: "CANADA",
            AdresseEmail1: "info@bengale.ca",
            TitrePays1: "CANADA",
            TextRight: "A member of our team will respond to you as soon as possible."
        }

    ]
}
];










