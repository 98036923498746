import firebase from '../Utlitaires/Firebase';

export const GET_HOME = "GET_HOME";

export const Get_Home = () => {
    return (dispatch) => {

        const LireHomeBD = firebase.database().ref("Home");

        LireHomeBD.on("value", (snapshot) => {
            let prelist = snapshot.val();
            dispatch({ type: GET_HOME, payload: prelist })
        });
    }
}

