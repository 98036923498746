import { NavLink } from "react-router-dom";
import { menu } from "../Database/dataMenu";
import { useState } from "react";
import { ChoixLangue } from './Context/LangueContext';
import { useContext } from "react";
import { useEffect } from "react";
import Mouse from "./Mouse";


const SousMenu = () => {


    const [DataLANGUE, setDataLANGUE] = useState(menu[0].FR);
    const [Lang, setLang] = useState("EN");

    // const { toggleLangue } = useContext(ChoixLangue);
    const { LANGUE } = useContext(ChoixLangue)

    const CurseurNormal = () => {

        const curseur = document.querySelector(".curseur.hover");
        console.log(curseur);
        curseur !== null && curseur.classList.remove("hover");
    }


    useEffect(() => {



        if (LANGUE === 0) {
            setDataLANGUE(menu[0].FR);
            setLang("EN");
        } else {
            setDataLANGUE(menu[0].EN);
            setLang("FR");
        }

    }, [LANGUE])

    return (

        <div
            onMouseLeave={CurseurNormal}
            className="sous-menu">

            <NavLink exact to={DataLANGUE[1].link} className={(nav) => nav.isActive ? "Sous-nav-active" : "titre-sous-menu"}>
                {DataLANGUE[1].titre}
            </NavLink>
            <NavLink exact to={DataLANGUE[2].link} className={(nav) => nav.isActive ? "Sous-nav-active" : "titre-sous-menu"}>
                {DataLANGUE[2].titre}
            </NavLink>
            <NavLink exact to={DataLANGUE[3].link} className={(nav) => nav.isActive ? "Sous-nav-active" : "titre-sous-menu"}>
                {DataLANGUE[3].titre}
            </NavLink>
        </div>

    );
};

export default SousMenu;