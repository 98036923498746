import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./styles/index.scss";

//Redux
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import rootReducer from "./Reducers";
import { Get_Home } from "./Actions/home.action";

const store = createStore(
  rootReducer, //Permet de lire le fichier index.js pour créer plusieur reducer
  //composeWithDevTools(applyMiddleware(thunk))) // Version Dev
  applyMiddleware(thunk)); // Version Prod

store.dispatch(Get_Home());

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

