import React, { useContext, useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Copyright from '../components/Copyright';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import ImageFond from '../assets/contact/IMG_5969-2-1.webp';
import ImageMontreal from '../assets/contact/Montreal.webp';
import { ChoixLangue } from '../components/Context/LangueContext';
import { contact } from "../Database/dataContact";

const Contact = () => {


    const [DataContact, setDataContact] = useState(contact[0].FR);
    const [Lang, setLang] = useState("EN");
    const [Message, setMessage] = useState("");

    const { toggleLangue } = useContext(ChoixLangue);
    const { LANGUE } = useContext(ChoixLangue)


    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm("service_lfucqvo", "template_i88hmed", form.current, "UpdATKsVE-cmzoeJu")
            .then((result) => {
                setMessage('Votre demande a été envoyée');
                setTimeout(() => {
                    setMessage("");
                }, 3000);
            }, (error) => {
                setMessage('Une erreur est survenue !');
                setTimeout(() => {
                    setMessage("");
                }, 3000);
            });
    }


    useEffect(() => {

        if (LANGUE === 0) {
            setDataContact(contact[0].FR);
            setLang("EN");
        } else {
            setDataContact(contact[0].EN);
            setLang("FR");
        }

    }, [LANGUE])

    return (
        <div className="Contact">
            <Navigation />
            <img className="Image-Fond" src={ImageFond} alt="Picture" />
            <div className="degrade"></div>
            <div className="Form-Contact">
                <h3 className='titre-1-contact'>{DataContact[0].titre1}</h3>
                <h2 className='titre-2-contact'>{DataContact[0].titre2}</h2>

                <div className="containt-form">

                    <div className="left">
                        <h3 className='titre-1-form'>{DataContact[0].formTitreLeft}</h3>

                        <form ref={form} onSubmit={sendEmail}>
                            <input type="text" id="user_nom" name="user_nom" placeholder={DataContact[0].formNom} />
                            <input type="email" id="user_email" name="user_email" placeholder={DataContact[0].formEmail} />
                            <textarea type="area" id="message" name="message" placeholder={DataContact[0].formMessage} />

                            <button type="submit">{DataContact[0].formBouton}</button>
                        </form>
                        <div className='message'>{Message}</div>
                        {/* <div className="adresse-email">
                            <p>{DataContact[0].AdressePays1}</p>
                            <p>{DataContact[0].AdresseEmail1}</p>
                        </div> */}
                    </div>
                    <div className="right">
                        <h3 className='titre-1-form'>{DataContact[0].TitrePays1}</h3>
                        <img className="Image-Fond" src={ImageMontreal} alt="Picture" />
                        <p>{DataContact[0].TextRight}</p>
                    </div>

                </div>

            </div>



            <Footer />
            <Copyright />
        </div>
    );
};

export default Contact;