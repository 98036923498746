import React from "react";
import { NavLink } from "react-router-dom";
import { menu } from "../Database/dataMenu";
import { ChoixLangue } from './Context/LangueContext';
import { useContext, useEffect, useState } from "react";
import { isEmpty } from "../Utlitaires/isEmpty";
import { useSelector } from "react-redux";

import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";




const Footer = () => {

    const HomeDB = useSelector((state) => state.homeReducer);

    const [DataLANGUE, setDataLANGUE] = useState(menu[0].FR);
    const [Lang, setLang] = useState("EN");

    const { toggleLangue } = useContext(ChoixLangue);
    const { LANGUE } = useContext(ChoixLangue)

    useEffect(() => {

        if (LANGUE === 0) {
            setDataLANGUE(menu[0].FR);
            setLang("ENGLISH");
        } else {
            setDataLANGUE(menu[0].EN);
            setLang("FRANCAIS");
        }

    }, [LANGUE])


    return (
        <div className="containt-footer">
            <div className="footer">
                {!isEmpty(HomeDB) && (
                    <div className="reseau-sociaux">

                        {/* <a href={HomeDB[LANGUE].lienfacebook} className="fab"><FaFacebook /></a>
                        <a href={HomeDB[LANGUE].lienyoutube} className="fab"><FaInstagram /></a>
                        <a href={HomeDB[LANGUE].lieninstagram} className="fab"><FaYoutube /></a> */}
                        {/* <a href={HomeDB[LANGUE].lienlinkedin} className="fab fa-linkedin"></a> */}
                    </div>)}
                {!isEmpty(HomeDB) && (
                    <div className="etablissement">
                        <h2 className="pays">{HomeDB[LANGUE].Pays1}</h2>
                        <h3 className="ville">{HomeDB[LANGUE].Ville1}</h3>
                        <h4 className="telephone">{HomeDB[LANGUE].Telephone1}</h4>
                    </div>
                )}
                {!isEmpty(HomeDB) && (
                    <div className="etablissement">
                        <h2 className="pays">{HomeDB[LANGUE].Pays2}</h2>
                        <h3 className="ville">{HomeDB[LANGUE].Ville2}</h3>
                        <h4 className="telephone">{HomeDB[LANGUE].Telephone2}</h4>
                        <h3 className="ville">{HomeDB[LANGUE].Ville3}</h3>
                        <h4 className="telephone">{HomeDB[LANGUE].Telephone3}</h4>
                    </div>
                )}
                <div className="liens">
                    <h2 className="pays">{DataLANGUE[6].titre}</h2>

                    <NavLink exact to="/" >
                        <h3 onClick="#">{DataLANGUE[7].titre}</h3>
                    </NavLink>
                    <NavLink exact to="/contact" activeClassName="nav-active">
                        <h3 >{DataLANGUE[5].titre}</h3>
                    </NavLink>
                    <button onClick={toggleLangue}>{Lang}</button>

                </div>
                {/* <form className="inscription">
                    <input type="text" placeholder={DataLANGUE[8].titre} />
                    <input type="text" placeholder="E-mail" />
                    <button>{DataLANGUE[9].titre}</button>

                </form> */}



            </div>
        </div>
    );
};

export default Footer;


