export const ExpMediaSociaux = [{
    FR: [
        {
            Titre1: "Bengale s'occupe de tout",
            Paragraphe1: "De la création de contenus à la publication, Bengale vous offre un service 360. Nous mettons en place une stratégie pour cibler, sensibiliser et communiquer pour un maximum d’audience.",
            Titre2: "Bengale vous accompagne avec récurence",
            Paragraphe2: "Les réseaux sociaux créent une grande visibilité et le contenu en est la base. La récurrence joue un jeu primordial dans n’importe quelle stratégie.",
            Titre3: "Bengale crée votre contenu",
            Paragraphe3: "Bengale est toujours à la recherche de nouvelles idées innovatrices et créatives. Notre politique, la qualité, les détails et beaucoup de passions.",
            EquipeAgence: "STUDIO BENGALE",
            EquipeTitre1: "NOTRE ÉQUIPE",
            EquipeTitre2: "Bengale s'occupe de tout."
        }
    ],
    EN: [
        {
            Titre1: "Bengale takes care of everything",
            Paragraphe1: "From content creation to publication, Bengale offers you a 360° service. We implement a strategy to target, educate and communicate for maximum audience.",
            Titre2: "Bengale accompanies you with recurrence",
            Paragraphe2: "Social networks create great visibility and content is the foundation. Recurrence plays a vital game in any strategy.",
            Titre3: "Bengal creates your content",
            Paragraphe3: "Bengale is always on the lookout for new, innovative and creative ideas. Our policy, quality, details and many passions.",
            EquipeAgence: "BENGALE STUDIO",
            EquipeTitre1: "OUR TEAM",
            EquipeTitre2: "Bengale takes care of everything"
        }

    ]
}
];










