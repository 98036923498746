import React, { useContext, useEffect, useState } from "react";
import Copyright from "../../components/Copyright";
import Footer from "../../components/Footer";
import Navigation from "../../components/Navigation";
import Image1 from "../../assets/FilmsEtImages/Image-1.webp"
import Image2 from "../../assets/FilmsEtImages/Image-2.webp"
import CartePostes from "../../components/CartePostes";
import { ChoixLangue } from '../../components/Context/LangueContext';
import { ExpProd } from "../../Database/dataExpProd";

const FilmsImages = () => {

  const [DataProd, setDataProd] = useState(ExpProd[0].FR);
  const [Lang, setLang] = useState("EN");

  // const { toggleLangue } = useContext(ChoixLangue);
  const { LANGUE } = useContext(ChoixLangue)

  useEffect(() => {

    if (LANGUE === 0) {
      setDataProd(ExpProd[0].FR);
      setLang("EN");
    } else {
      setDataProd(ExpProd[0].EN);
      setLang("FR");
    }

  }, [LANGUE])

  return (

    <div className="ProdFilms">
      <Navigation />

      <div className="Section-1">

        <h2 className="titre1">{DataProd[0].Titre1}</h2>

        <iframe className="petit-film" src={"https://player.vimeo.com/video/" + "552445638" + "?background=1&autoplay=1&loop=1&byline=0&title=0"}>VIDEO </iframe>
        <p className="p-1">{DataProd[0].Paragraphe1A} <br /><br />
          {DataProd[0].Paragraphe1B}</p>
      </div>


      <div className="Section-2">
        <h2 className="titre2">{DataProd[0].Titre2}</h2>

        <iframe className="petit-film" src={"https://player.vimeo.com/video/" + "553764269" + "?background=1&autoplay=1&loop=1&byline=0&title=0"} > </iframe>
        <p>{DataProd[0].Paragraphe2A} <br /><br />
          {DataProd[0].Paragraphe2B}</p>
      </div>


      <div className="Section-3">
        <h2 className="titre3">{DataProd[0].Titre3}</h2>

        <iframe className="petit-film" src={"https://player.vimeo.com/video/" + "251384998" + "?background=1&autoplay=1&loop=1&byline=0&title=0"} > </iframe>
        <p>{DataProd[0].Paragraphe3A}<br /><br />
          {DataProd[0].Paragraphe3B}</p>
      </div>

      <div className="Section-4">
        <h2 className="titre4">{DataProd[0].Titre4}</h2>

        <img className="Petite-Photo-1" src={Image1} alt="Picture" />
        <p className="p-1">{DataProd[0].Paragraphe4}</p>
        <p className="p-2">{DataProd[0].Paragraphe5}</p>
      </div>

      <div className="Section-5">
        <img className="Petite-Photo-1" src={Image2} alt="Picture" />

      </div>

      <div className="Section-6">
        <h3>{DataProd[0].EquipeAgence}</h3>
        <h2 >{DataProd[0].EquipeTitre1}</h2>
        <h2 className="sous-titre">{DataProd[0].EquipeTitre2}</h2>

      </div>
      <CartePostes />
      <Footer />
      <Copyright />
    </div>
  );
};

export default FilmsImages;
