import React, { useContext, useEffect, useState } from "react";
import Copyright from "../../components/Copyright";
import Footer from "../../components/Footer";
import Navigation from "../../components/Navigation";

import Image1 from "../../assets/web/petite-image-1.png"
import CartePostesWeb from "../../components/CartePostesWeb";
import { ChoixLangue } from '../../components/Context/LangueContext';
import { ExpWeb } from "../../Database/dataExpWeb";

const WebSite = () => {

  const [DataWeb, setDataWeb] = useState(ExpWeb[0].FR);
  const [Lang, setLang] = useState("EN");

  const { LANGUE } = useContext(ChoixLangue)

  useEffect(() => {

    if (LANGUE === 0) {
      setDataWeb(ExpWeb[0].FR);
      setLang("EN");
    } else {
      setDataWeb(ExpWeb[0].EN);
      setLang("FR");
    }

  }, [LANGUE])


  return (

    <div className="Web">
      <Navigation />

      <div className="Section-1">
        <div className="left">

          <iframe className="film-internet" src={"https://player.vimeo.com/video/" + "553947635" + "?background=1&autoplay=1&loop=1&byline=0&title=0"} > </iframe>
        </div>
        <div className="right">
          <h1>{DataWeb[0].Titre1}</h1>
          <p>{DataWeb[0].Paragraphe1}</p>
        </div>

      </div>
      <div className="Section-2">
        <div className="left">
          <h1>{DataWeb[0].Titre2}</h1>
          <p>{DataWeb[0].Paragraphe2}</p>
        </div>
        <div className="right">
          <img className="Photo-e-commerce" src={Image1} alt="Picture" />
        </div>
      </div>
      <div className="Section-3">
        <h3>{DataWeb[0].EquipeAgence}</h3>
        <h2 >{DataWeb[0].EquipeTitre1}</h2>
        <h2 className="sous-titre">{DataWeb[0].EquipeTitre2}</h2>

      </div>
      <CartePostesWeb />
      <Footer />
      <Copyright />


    </div>

  );
};

export default WebSite;
