import React from 'react';
import { useParams } from 'react-router-dom';
import Navigation from './Navigation';
import { images } from "../Database/dataProduction";
import Logo from './Logo';

const EcranImages = () => {


    const { id } = useParams();

    var lien = images.filter((e) => e.id === id)


    return (
        <>
            <div className="nav" >
                <Navigation fixe={true} /> {/*  fixe true si menu nav position: fixe  */}
            </div>

            <div className="Image" >
                <img src={lien[0].Photo} alt={lien[0].Titre} />
            </div>
        </>
    );
};

export default EcranImages;