import { BrowserRouter, Routes, Route } from "react-router-dom"  // Ces bibliothèque serve à faire une Navigation
import Productions from './pages/Productions';
import Contact from './pages/Contact';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Expertises from './pages/Expertises';
import ChoixLangueProvider from './components/Context/LangueContext';
import FilmsImages from './pages/SousPages/FilmsImages';
import WebSite from './pages/SousPages/WebSite';
import MediaSociaux from './pages/SousPages/MediaSociaux';
import Mouse from "./components/Mouse";
import EcranFilms from "./components/EcranFilms";
import EcranImages from "./components/EcranImages";

const App = () => {

  return (
    <BrowserRouter>
      <ChoixLangueProvider >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/productions" element={<Productions />} />
          <Route path="/ecran-film/:id" element={<EcranFilms />} />
          <Route path="/ecran-image/:id" element={<EcranImages />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/expertises" element={<Expertises />} />
          <Route path="/films-image" element={<FilmsImages />} />
          <Route path="/site-web" element={<WebSite />} />
          <Route path="/social-media" element={<MediaSociaux />} />
          <Route component={<NotFound />} />
        </Routes>
      </ChoixLangueProvider>
    </BrowserRouter>
  );
};

export default App;