import Photo1 from "../assets/web/web-photo-1.webp"
import Photo2 from "../assets/web/web-photo-2.webp"
import Photo3 from "../assets/web/web-photo-3.webp"
import Photo4 from "../assets/web/web-photo-4.webp"
import Photo5 from "../assets/web/web-photo-5.webp"
import Photo6 from "../assets/web/web-photo-6.webp"


export const CartesWeb = [{
    FR: [
        {
            Photo: Photo1,
            Titre: "Concepteur web",
            SousTitre: "équipe Web",
            Text: "La conception est la colonne vertébrale d’un site. Les 20 ans d’expérience de nos concepteurs web, mettent cette expérience à votre service."
        },
        {
            Photo: Photo2,
            Titre: "Web designer",
            SousTitre: "équipe Web",
            Text: "Nos graphistes maitrisent l’art et la technique, ils donneront à votre site clarté et élégance."
        },
        {
            Photo: Photo3,
            Titre: "Développeur web",
            SousTitre: "Spécialiste web & application mobile",
            Text: "Nos développeurs web réalisent votre site web et e-commerce avec les meilleures technologies. Lorsque nous avons besoin de sortir du standard, nos développeurs prennent le relais. "
        },
        {
            Photo: Photo4,
            Titre: "Rédacteur",
            SousTitre: "équipe Web",
            Text: "Nos rédacteurs sont les mieux placés pour développer par écrit ce que vous pensez. "
        },
        {
            Photo: Photo5,
            Titre: "Traducteur",
            SousTitre: "spécialiste en langue étrangère.",
            Text: "Nos traducteurs vous aideront à communiquer dans le monde entier."
        },
        {
            Photo: Photo6,
            Titre: "Spécialiste SEO",
            SousTitre: "équipe Web",
            Text: "Les spécialistes SEO optimisent votre site pour un meilleur référencement, pour plus de visibilité."
        }
    ],
    EN: [
        {
            Photo: Photo1,
            Titre: "Web Conceptor",
            SousTitre: "web team",
            Text: "The conception is the backbone of a site. The 20 years of experience of our web designers, put this experience at your service."
        },
        {
            Photo: Photo2,
            Titre: "Web designer",
            SousTitre: "web team",
            Text: "Our graphic designers master the art and technique, they will give your site clarity and elegance."
        },
        {
            Photo: Photo3,
            Titre: "Web Developer",
            SousTitre: "Spécialiste web & application mobile",
            Text: "Our web developers create your website and e-commerce with the best technologies. When we need to go beyond the standard, our developers take over."
        },
        {
            Photo: Photo4,
            Titre: "Editors",
            SousTitre: "web team",
            Text: "Our writers are in the best position to develop in writing what you think."
        },
        {
            Photo: Photo5,
            Titre: "Translator",
            SousTitre: "foreign language specialist.",
            Text: "Our translators will help you communicate around the world."
        },
        {
            Photo: Photo6,
            Titre: "SEO Specialist",
            SousTitre: "web team",
            Text: "The SEO specialists optimize your site for a better referencing, for more visibility."
        }

    ]

}];